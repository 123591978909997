/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: 'AI工具导航',
  // 网站标题
  siteTitle: 'AI工具导航 - 精选与自定义的简洁AI导航网站',
  // 网站加载时显示的内容
  siteLoading: '正在为您加载系统资源，请稍候片刻...',
  // 网站描述
  siteDescription: '本网站是一个智能化、简洁高效的AI工具导航平台，专为用户提供便捷的在线工具导航。用户可以自定义导航，个性化布局，并访问数百种AI相关工具，包括图片生成、自然语言处理、机器学习、数据分析等领域。格姗导航收录丰富的优质资源，涵盖了格式转换、设计素材、在线工具、生产力应用等，助力用户快速查找与使用各类AI工具。',
  // 网站关键字
  siteKeywords: 'AI工具导航,导航网站,智能导航,AI工具,自定义导航,生产力工具,格式转换,图片素材,机器学习,数据分析,自然语言处理',
  // 白名单配置，不用令牌就能访问
  whileList: ['/', '/login', '/about'],
  // 超级管理员
  superAdmin: 'superAdmin',
  // 所有权限
  allPermission: '*:*:*',
  // ICP备案号
  recordTitle: '苏ICP备2024095035号-1',
  // 公安备案号
  publicSecurityRecordNumber: '苏公网安备32040002010398'
}
